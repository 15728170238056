.footer
  @apply bg-grey-200 pb-[40px] pt-[60px] lg:pt-[75px]
  // Sticky footer 2/2: move footer downwards if there is empty space in between sections
  @apply mt-auto

  & > * + *
    @apply mt-[60px] md:mt-[52px] lg:mt-[72px]

  .section
    @apply flex flex-col

    & > li + li
      @apply mt-[10px]

  .sectionItem
    @apply font-neutral text-grey-900 flex items-center

    .link
      @apply text-grey-800 hover:text-blue-500

      :global(.dark) &
        @apply text-grey-200 hover:text-green-500

    .count
      @apply bg-grey-300

      :global(.dark) &
        @apply bg-grey-700 

  .sectionHeading
    @apply mb-[20px]

  .newsletterHeading
    @apply font-neutral text-grey-800 mb-[16px]

  .icons
    @apply inline-flex mb-[30px]
    // Icons clickable area is bigger than inner visible icons, so we're using negavite margins to align them
    @apply mt-[-10px] ml-[-10px]

    & > * + *
      @apply ml-[10px]

  .bottomLinks
    @apply flex mb-[12px] md:mb-0 md:justify-end

    & > * + *
      @apply ml-[30px]

  .bottomLink, .copyright
    @apply font-neutral text-grey-600

    .link
      @apply text-grey-600 hover:text-blue-500

      :global(.dark) &
        @apply text-grey-200 hover:text-green-500

:global(.dark)
  .footer
    @apply bg-grey-800

    .sectionItem, .bottomLink, .copyright, .newsletterHeading
      @apply text-grey-200
