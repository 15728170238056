.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper label {
  @apply select-none;

  display: none;
  font-size: 10px;
  line-height: 15px;
}

.wrapper label.active {
  display: inline;
  width: max-content;
}

.container {
  @apply flex justify-around bg-grey-500 rounded-full items-center mb-3 px-[2px] cursor-pointer;

  width: 120px;
  height: 40px;
}

:global(.dark) .container {
  @apply bg-black;
}

.radio {
  @apply bg-grey-300 rounded-full cursor-pointer border-none;

  width: 6px;
  height: 6px;
  box-shadow: none;
  align-self: center;
}

:global(.dark) .radio {
  @apply bg-grey-600;
}

.radio.active {
  @apply border-none bg-white;

  width: 25px;
  height: 25px;
  margin: -9px;
  background-image: none;
  box-shadow: none;
}

:global(.dark) .radio.active {
  @apply bg-white;
}

.mode {
  @apply font-neutral;
}

@screen md {
  .container {
    width: 70px;
    height: 30px;
  }

  .radio {
    width: 4px;
    height: 4px;
  }

  .radio.active {
    width: 14px;
    height: 14px;
    margin: -5px;
  }
}
