.base {
  @apply text-black;
}

:global(.dark) .base {
  @apply text-white;
}

.headline {
  @apply font-headline leading-headline tracking-headline;
}

.mono {
  @apply font-mono leading-loose tracking-headline;
}

.smallSubheading {
  @apply font-sans font-median tracking-base leading-loose;
}

.bigSubheading {
  @apply font-sans font-median tracking-base leading-tight;
}

.smallBody {
  @apply font-sans font-slim tracking-base leading-loose;
  @apply text-grey-800;
}

:global(.dark) .smallBody {
  @apply text-grey-100;
}

.bigBody {
  @apply font-sans font-slim tracking-base leading-tight;
  @apply text-grey-800;
}

:global(.dark) .bigBody {
  @apply text-grey-100;
}

.smallCaption {
  @apply font-sans font-neutral tracking-base uppercase leading-loose;
}

.bigCaption {
  @apply font-sans font-neutral tracking-base uppercase leading-tight;
}
