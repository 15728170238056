.socialIconWrapper {
  @apply flex items-center justify-center rounded-full;
  width: 40px;
  height: 40px;

  .socialIcon {
    @apply transition;
    width: 16px;
  }

  &:hover .socialIcon {
    @apply text-blue-500;
  }
}

:global(.dark) {
  .socialIconWrapper:hover .socialIcon {
    @apply text-green-500;
  }
}

