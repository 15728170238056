.link {
  @apply text-black relative hover:text-blue-500 transition;
}

:global(.dark) .link {
  @apply text-white hover:text-green-500;
}

.link.underlined::after {
  @apply border-black transition;

  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.link.underlined:hover::after {
  @apply border-blue-500 transition;
}

:global(.dark) .link.underlined::after {
  @apply border-white;
}

:global(.dark) .link.underlined:hover::after {
  @apply border-green-500;
}
