.form
  @apply relative

  .textInput
    @apply text-[16px] md:text-[14px] lg:text-[16px] text-white font-neutral w-full pt-[12px] pb-[13px] pl-[20px] h-[44px]
    @apply rounded-full border-2 border-transparent transition
    @apply focus:border-green focus:border-2 focus:shadow-green focus:ring-green

    &.base
      @apply bg-grey-200 text-black

      &::placeholder
        @apply text-grey-500

      :global(.dark) &
        @apply bg-grey-700 text-white

        &::placeholder
          @apply text-grey-200

    &::placeholder
      @apply font-neutral text-grey-300

    &.footer
      @apply bg-white text-grey-800

    &.community
      @apply w-full text-white bg-blue-800

      &.submitted
        @apply text-green-500

    &.ai
      @apply bg-grey-100 text-xl h-[50px] text-grey-500
      @apply focus:border-pink-500 focus:shadow-pink-500 focus:ring-pink-500
      &::placeholder
        @apply text-grey-300

      :global(.dark) &
        background: #282425

        &::placeholder
          @apply text-grey-600


    &.submitted
      @apply text-black

  .button
    @apply absolute flex justify-center items-center h-[38px] w-[38px] right-[3.5px] top-[3px]
    @apply rounded-full bg-green cursor-pointer transition
    @apply hover:scale-[0.85] focus:outline-none

    &.ai
      @apply w-[44px] h-[44px] bg-pink-500

  .icon
    @apply text-black w-[8px]

    &.submitted
      @apply w-[14px]


:global(.dark)
  .form
    .textInput
      @apply text-white

      &.footer
        @apply bg-grey-700

      &.community
        @apply bg-blue-800

      &.submitted
        @apply text-green-500
